<template>
  <div style>
    <div class="interMant_top">
      <div class="interTop_left  ">
        <el-avatar size="large" class="tu" :src="'http://' + infoData.head_img"></el-avatar>
        <div class="_omit">
          姓名:
          <span>{{infoData.name}}</span>
          性别:
          <span>{{infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : ''}}</span>
          年龄:
          <span>{{infoData.birthday}}岁</span>
          运动队:
          <span>{{infoData.department_name}}</span>
          项目:
          <span>{{infoData.sport_name}}</span>
          教练:
          <span>{{infoData.coach_name}}</span>
        </div>
      </div>
      <span @click="reBack" class="_ret">
        <i class="iconfont iconfanhui"></i>
        返回
      </span>
    </div>
    <div class="child-top" style="padding-top: 0;">
      <div class="input-from">
        <el-date-picker
          v-model="value1"
          type="daterange"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="create()"
        ></el-date-picker>
        <el-select clearable placeholder="恢复情况" @change="create()" v-model="recoveryDate">
          <el-option :label="'已恢复'" :value = '1'></el-option>
          <el-option :label="'恢复中'" :value = '0'></el-option>
        </el-select>
        <el-select clearable placeholder="伤病部位" @change="create()" v-model="partDataValue">
          <el-option
            :label="item.name"
            :value="item.uuid"
            v-for="item in partData"
            :key="item.uuid"
          ></el-option>
        </el-select>
        <el-input
          @keyup.enter.native="create(true)"
          suffix-icon="iconfont iconsousuo"
          clearable
          placeholder="请输入治疗措施关键字"
          style="width: 200px;"
          v-model="input4"
        ></el-input>
        <el-button @click="create(true)" class="searchBtn" round>搜索</el-button>
      </div>
      <div class="operation-button">
        <!-- :disabled="$store.getters.permissionsStr('新增')" -->
        <el-button @click="addDataLog" class="new_btn" round :disabled="$store.getters.permissionsStr('新增')">
          <span>新增</span>
        </el-button>
        <!-- :disabled="$store.getters.permissionsStr('删除')" -->
        <el-button @click="delDataLog" class="del_btn" round :disabled="$store.getters.permissionsStr('删除')">
          <span>删除</span>
        </el-button>
      </div>
    </div>
    <div class="content-flex-pages">
      <el-table
        :empty-text="tableData.length ? '' : '暂无数据'"
        :data="tableData"
        @selection-change="tableSelectionChange"
        class="content-test-table"
        style="width: 100%;"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="45"></el-table-column>
        <el-table-column label="序号" type="index" width="45">
          <template slot-scope="scope">{{ (currentPage - 1) * 10 + scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="损伤时间" prop="start_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="伤病部位" prop="injury_position_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="恢复时间" prop="recovery_date" show-overflow-tooltip></el-table-column>
        <el-table-column label="附件" prop="filter" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-show="scope.row.uuid"
              type="text"
              style="color: #0055E9;"
              @click="uploadBtn(scope.row)"
              :disabled="$store.getters.permissionsStr('附件')"
            >{{`附件 (${scope.row.annex_quantity >= 0 ? scope.row.annex_quantity : ''})`}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              class="right20"
              style="color: #0055E9;"
              size="medium"
              type="text"
              @click="editorData(scope.row, scope.$index)"
              :disabled="scope.row.status == '0' ? true : false || $store.getters.permissionsStr('编辑')"
              v-show="scope.row.uuid"
            >编辑</el-button>
            <el-button
              class="right20"
              style="color: #1A9F16;"
              size="medium"
              type="text"
              @click="lookMant(scope.row, scope.$index)"
              :disabled="scope.row.status == '0' ? true : false || $store.getters.permissionsStr('查看')"
              v-show="scope.row.uuid"
            >查看</el-button>
            <el-button
              @click="delTableData(scope.row)"
              class="redColor"
              size="medium"
              style="color: #FD3258;"
              type="text"
              v-show="scope.row.uuid"
              :disabled="$store.getters.permissionsStr('删除')"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="currentPage"
        :page-size="10"
        :total="tableTotal"
        @current-change="handleCurrentChange"
        class="table-pagination"
        layout="total, prev, pager, next , jumper"
      ></el-pagination>
    </div>

    <!-- 新建、编辑弹框 -->
    <el-dialog
      :title="dislogName"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="closeNew"
      :close-on-click-modal="false"
      center
      :show-close="false"
      class="newDialog"
    >
      <div class="form_bot" v-loading="fileLoading">
        <div class="interTop_left">
          <el-avatar size="large" class="tu" :src="'http://' + infoData.head_img"></el-avatar>
          <div style="flex: 1;"  class="_omit">
            姓名:
            <span>{{infoData.name}}</span>
            性别:
            <span>{{infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : ''}}</span>
            年龄:
            <span>{{infoData.birthday}}岁</span>
            运动队:
            <span>{{infoData.department_name}}</span>
            项目:
            <span>{{infoData.sport_name}}</span>
            教练:
            <span>{{infoData.coach_name}}</span>
          </div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForms"
          label-width="100px"
          class="demo-ruleForm"
          label-position="left"
        >
          <div class="formItem_cen">
            <el-form-item label="损伤时间" prop="start_date" style>
              <el-date-picker
                v-model="ruleForm.start_date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="损伤部位" prop="injury_position_uuid" style="margin-left:20px ;">
              <el-select v-model="ruleForm.injury_position_uuid" placeholder="请选择损伤部位">
                <el-option
                  v-for="item in partData"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="formItem_cen">
            <el-form-item label="伤病类型" prop="injury_cate_uuid">
              <el-select  v-model="ruleForm.injury_cate_uuid" placeholder="请选择伤病类型">
                <el-option
                  v-for="item in typeData"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="伤病程度" prop="injury_degree_uuid" style="margin-left:20px ;">
              <el-select v-model="ruleForm.injury_degree_uuid" placeholder="请选择伤病程度">
                <el-option
                  v-for="item in degreeData"
                  :key="item.uuid"
                  :label="item.name"
                  :value="item.uuid"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="治疗措施" prop="treat_measure">
            <el-input  v-model.number="ruleForm.treat_measure" ></el-input>
            
          </el-form-item>
          <div class="formItem_cen">
            <el-form-item label="恢复时间" prop="recovery_date">
              <el-date-picker
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                v-model="ruleForm.recovery_date"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="影响训练天数" prop="influence_train_days" style="margin-left:20px ;">
              <el-input type="number" v-model="ruleForm.influence_train_days" maxlength="20"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="是否影响比赛" prop="influence_match">
            <el-radio-group v-model="ruleForm.influence_match">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="formItem_cen" v-if="ruleForm.influence_match">
            <el-form-item label="比赛名称" prop="influence_match_name">
              <el-input maxlength="30" v-model="ruleForm.influence_match_name" ></el-input>
            </el-form-item>
            <el-form-item label="影响比赛天数" prop="influence_match_days">
              <el-input v-model="ruleForm.influence_match_days" maxlength="20"></el-input>
            </el-form-item>
          </div>
          <el-form-item label="备注" prop="demo">
            <el-input  v-model.number="ruleForm.demo"></el-input>
          </el-form-item>
        </el-form>
        <!-- <div class="inter_file">
          <p> <span></span> 上传附件</p>
          <el-upload
            :action="`${$store.state.img_url}/p/annex/add`"
            ref="upload"
            :with-credentials="false"
            :show-file-list='true'
            :on-success='success'
            :on-remove="remove"
            name='file'
            list-type="picture-card"
            :file-list="newFileList">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file}">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url" alt=""
                >
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
          </el-upload>
          <el-dialog :visible.sync="imgDialog" append-to-body class="append_dialog">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </div>-->
      </div>
      <span slot="footer" class="form-bottom-button">
        <el-button type="primary" @click="newSave" class="save_btn" round>确 定</el-button>
        <el-button @click="closeNew" class="cancel_btn" round>取 消</el-button>
      </span>
    </el-dialog>
    <!-- 查看弹框 -->
    <el-dialog
      title="查看伤病记录"
      :visible.sync="lookDialogVisible"
      width="60%"
      :show-close="false"
      :close-on-click-modal="false"
      center
    >
      <div class="form_bot" v-loading="fileLoading">
        <div class="interTop_left">
          <el-avatar size="large"  class="tu" :src="'http://' + infoData.head_img"></el-avatar>
          <div style="flex: 1;"  class="_omit">
            姓名:
            <span>{{infoData.name}}</span>
            性别:
            <span>{{infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : ''}}</span>
            年龄:
            <span>{{infoData.birthday}}岁</span>
            运动队:
            <span>{{infoData.department_name}}</span>
            项目:
            <span>{{infoData.sport_name}}</span>
            教练:
            <span class="_omit">{{infoData.coach_name}}</span>
          </div>
        </div>
        <div class="tableBorder_center">
          <div class="formItem_cen">
            <div class="label">损伤时间</div>
            <div class="value">{{lookData.start_date}}</div>
            <div class="label">损伤部位</div>
            <div class="value">{{lookData.injury_position_name}}</div>
          </div>

          <div class="formItem_cen">
            <div class="label">伤病类型</div>
            <div class="value" >{{lookData.injury_cate_name}}</div>
            <div class="label">伤病程度</div>
            <div class="value">{{lookData.injury_degree_name}}</div>
          </div>

          <div class="formItem_cen">
            <div class="all_label">治疗措施</div>
            <div class="all_value ">{{lookData.treat_measure}}</div>
          </div>

          <div class="formItem_cen">
            <div class="label">恢复时间</div>
            <div class="value">{{lookData.recovery_date}}</div>
            <div class="label">影响训练天数</div>
            <div class="value">{{lookData.influence_train_days}}</div>
          </div>

          <div class="formItem_cen">
            <div class="all_label">是否影响比赛</div>
            <div class="all_value">{{lookData.influence_match ? '是' : '否'}}</div>
          </div>

          <div class="formItem_cen">
            <div class="label">比赛名称</div>
            <div class="value">{{lookData.influence_match_name}}</div>
            <div class="label">影响比赛天数</div>
            <div class="value">{{lookData.influence_match_days }}</div>
          </div>

          <div class="formItem_cen">
            <div class="all_label">备注</div>
            <div class="all_value all_value_a"  >{{lookData.demo}}</div>
          </div>

          <div class="formItem_cen">
            <div class="label">创建人</div>
            <div class="value">{{lookData.create_by}}</div>
            <div class="label">创建时间</div>
            <div class="value">{{lookData.create_time }}</div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="lookClose" round>关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 附件弹框 -->
    <el-dialog
      :title="`附件列表 (${fileNum})`"
      :visible.sync="fileDialogVisible"
      :close-on-click-modal="false"
      width="50%"
      :show-close="false"
      center
    >
      <div class="form_bot">
        <div class="interTop_left">
          <el-avatar size="large"  class="tu" :src="'http://' + infoData.head_img"></el-avatar>
          <div style="flex: 1;"  class="_omit">
            姓名:
            <span>{{infoData.name}}</span>
            性别:
            <span>{{infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : ''}}</span>
            年龄:
            <span>{{infoData.birthday}}岁</span>
            运动队:
            <span>{{infoData.department_name}}</span>
            项目:
            <span>{{infoData.sport_name}}</span>
            教练:
            <span>{{infoData.coach_name}}</span>
          </div>
        </div>
        <div class="inter_upload" v-loading="fileLoading">
          <div class="inter_opBtn">
            <el-upload
              class="upload-demo"
              :action="`${$store.state.img_url}/p/annex/add`"
              :on-success="success"
              :before-upload="beforeUpload"
              :data="{'master_uuid': info_uuid, 'last_path': '1'}"
              :show-file-list="false"
              :file-list="newFileList"
            >
              <el-button type="primary" round>上传</el-button>
            </el-upload>
            <el-button type="primary" @click="upload_down" round>下载</el-button>
            <el-button type="primary" round @click="option_del">删除</el-button>
          </div>
          <el-checkbox-group v-model="checkList">
            <ul class="upload_ul">
              <li v-for="(item, index) in files" :key="index" class="_ac"> 
                <div
                class="_across"
                  :class="checkList.indexOf(item.name) != -1 ? 'img_option img_active' : 'img_option'"
                  @click="fileTofile(item)"
                >
                  <img :src="item.url" alt />
                  <div class="img_dialog" v-if="checkList.indexOf(item.name) != -1"></div>
                </div>
                <el-checkbox :label="item.name">{{index + 1}}</el-checkbox>
                <p>{{item.name}}</p>
              </li>
            </ul>
          </el-checkbox-group>
        </div>
      </div>

      <el-dialog :visible.sync="imgDialog" append-to-body class="append_dialog">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
      <span slot="footer" class="form-bottom-button">
        <!-- <el-button @click="fileSave" class="save_btn" round>保 存</el-button> -->
        <el-button @click="fileClose" class="cancel_btn" round>关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import wordUrl from "./../../../static/word.jpg";
import xlsxUrl from "./../../../static/xlsx.jpg";
import pptsUrl from "./../../../static/ppts.jpg";
import pdfUrl from "./../../../static/pdfs.jpg";
import morenUrl from "./../../../static/file.png";
export default {
  data() {
    return {
      wordUrl: wordUrl,
      xlsxUrl: xlsxUrl,
      pptsUrl: pptsUrl,
      pdfUrl: pdfUrl,
      morenUrl: morenUrl,
      tableLoading: false,
      tableTotal: 0,
      currentPage: 1,
      roleState: "",
      input4: "",
      value1: [],
      imgData:"",
      dislogName: "新增伤病记录",
      dialogVisible: false,
      imgDialog: false,
      
      ruleForm: {
        start_date: null,
        influence_match: 0
      },
      rules: {
        start_date: { ...this.$rules.noEmpty, message: "请选择日期" },
        injury_position_uuid: {
          ...this.$rules.noEmpty,
          message: "请选择伤病部位"
        }
      },
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      partDataValue: "",
      infoData: {},
      newFormData: {},
      partData: [],
      typeData: [],
      degreeData: [],
      newFileList: [],
      files: [],
      tableData: [{uuid:0}],
      recoveryDate: '',
      lookDialogVisible: false,
      info_uuid: "",
      lookData: {},
      fileDialogVisible: false,
      fileNum: 0,
      selectChange: [],
      checkList: [],
      tableLoading: false,
      fileLoading: false
    };
  },
  mounted() {
    let myDate = new Date();
    let theDay =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
    this.value1 = [this.$lastYear(), theDay];
    this.create();
    this.serveInfo();
    // this.$axios.post("/p/injuryset/lists", this.$qs({ type: 2 })).then(res => {
    //   console.log(res.data)
    //   if(res.data.code == 0) {
    //     this.partData = res.data.data.rows
    //   }
    // })
    this.$axios
      .all([
        this.$axios.post("/p/injuryset/lists", this.$qs({ type: 1 , status : 1})),
        this.$axios.post("/p/injuryset/lists", this.$qs({ type: 2 , status : 1})),
        this.$axios.post("/p/injuryset/lists", this.$qs({ type: 3 , status : 1 }))
      ])
      .then(res1 => {
        this.typeData = res1[0].data.data.rows; // 损伤类型
        this.partData = res1[1].data.data.rows; // 损伤部位
        this.degreeData = res1[2].data.data.rows; // 伤病程度
      });
  },
  methods: {
    upload_down() {
      if(!this.checkList.length) {
        this.$message({
          type: 'error',
          message: '请选择要下载的附件'
        })
        return false  
      }
      let arr = [];
      this.checkList.forEach((item, index) => {
        this.files.forEach(itm => {
          if (item == itm.name) {
            arr.push(itm.file_path);
          }
        });
      });

      let path = "";
      arr.forEach((i, k) => {
        if (k == arr.length - 1) {
          path += `file_array[${k}]=${i}`;
        } else {
          path += `file_array[${k}]=${i}&`;
        }
      });

      let url = this.$store.state.img_url + "/p/download/zip?" + path;
      window.open(url);
    },
    option_del() {
      let arr = "";
      this.checkList.forEach((item, index) => {
        this.files.forEach(itm => {
          if (item == itm.name) {
            if (index == 0) {
              arr = itm.file_path;
            } else {
              arr += "," + itm.file_path;
            }
          }
        });
      });
      this.$axios
        .post(
          `/p/annex/delete`,
          this.$qs({
            file_path: arr
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message
            });
           this.fileNum = this.fileNum - this.checkList.length
            this.checkList.forEach((itm, index) => {
              var length = this.files.length
              while(length--) {
                if(this.files[length].name == itm) {
                  this.files.splice(length, 1)
                  this.checkList.splice(index, 1)
                }
              }
            })
          } else {
            this.$message({
              type: "error",
              message: res.data.message
            });
          }
        });
    },
    fileTofile(item) {
      this.imgDialog = true;
      this.dialogImageUrl = item.url;
    },
    fileSave() {
      // this.files.forEach(item => {
      //   item.url = ''
      // })
      this.$axios
        .post(
          "/p/annex/update",
          this.$qs({
            uuid: this.info_uuid,
            annex: JSON.stringify(this.files)
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message
            });
            // this.files = []
            // this.newFileList = []
            // this.fileDialogVisible = false
            // this.create()
          }
        });
    },
    fileClose() {
      this.fileDialogVisible = false;
      this.files = [];
      this.newFileList = [];
      this.checkList = [];
      this.create();
      this.fileLoading = false;
    },
    uploadBtn(row) {
      this.fileLoading = true;
      this.fileNum = row.annex_quantity;
      this.fileDialogVisible = true;
      this.info_uuid = row.uuid;

      this.$axios
        .post(
          "/p/annex/lists",
          this.$qs({
            uuid: row.uuid
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.files = res.data.data.rows;
            res.data.data.rows.forEach((itm, index) => {
              // item.url = 'http://' + item.file_path
              let type = this.nameMatch(itm);
              if (type) {
                this.files[index].url = type;
              } else {
                this.files[index].url = "http://" + this.files[index].file_path;
                // this.files.forEach(item => {
                //   if(item.name == itm.name) {
                //     item.url = 'http://' + item.file_path
                //   }
                // })
              }
            });
            this.newFileList = res.data.data.rows;
          }
          setTimeout(() => {
            this.fileLoading = false;
          }, 200);
        });
    },
    lookMant(row) {
      this.fileLoading = true;
      this.lookDialogVisible = true;
      this.$axios
        .post(
          "/p/injury/info",
          this.$qs({
            uuid: row.uuid
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.lookData = res.data.data[0];
            // this.typeData.forEach(item => {
            //   if(item.uuid == res.data.data[0].injury_cate_uuid) {
            //     this.lookData.injury_cate_name = item.name
            //   }
            // })
            // this.partData.forEach(item => {
            //   if(item.uuid == res.data.data[0].injury_position_uuid) {
            //     this.lookData.injury_position_name = item.name
            //   }
            // })
            // this.degreeData.forEach(item => {
            //   if(item.uuid == res.data.data[0].injury_degree_uuid) {
            //     this.lookData.injury_degree_name = item.name
            //   }
            // })

            this.typeData = this.onChangeData(
              this.typeData,
              this.ruleForm.injury_cate_uuid,
              this.ruleForm.injury_cate_name
            );
            this.partData = this.onChangeData(
              this.partData,
              this.ruleForm.injury_position_uuid,
              this.ruleForm.injury_position_name
            );
            this.degreeData = this.onChangeData(
              this.degreeData,
              this.ruleForm.injury_degree_uuid,
              this.ruleForm.injury_degree_name
            );
          }
          setTimeout(() => {
            this.fileLoading = false;
          }, 200);
        });
    },
    editorData(row) {
      this.dislogName = "编辑伤病记录";
      this.dialogVisible = true;
      this.fileLoading = true;

      this.info_uuid = row.uuid;
      this.$axios
        .post(
          "/p/injury/info",
          this.$qs({
            uuid: row.uuid
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.ruleForm = res.data.data[0];

            this.typeData = this.onChangeData(
              this.typeData,
              this.ruleForm.injury_cate_uuid,
              this.ruleForm.injury_cate_name
            );
            this.partData = this.onChangeData(
              this.partData,
              this.ruleForm.injury_position_uuid,
              this.ruleForm.injury_position_name
            );
            this.degreeData = this.onChangeData(
              this.degreeData,
              this.ruleForm.injury_degree_uuid,
              this.ruleForm.injury_degree_name
            );

            // this.newFileList = res.data.data.annex
            // this.files = res.data.data.annex

            // res.data.data.annex.forEach(itm => {
            //   let type = this.nameMatch(itm)
            //   if(type) {
            //     this.files.forEach(item=> {
            //       if(item.name == itm.name) {
            //         item.url = type
            //       }
            //     })
            //   } else {
            //     this.files.forEach(item => {
            //       if(item.name == itm.name) {
            //         item.url = 'http://' + item.file_path
            //       }
            //     })
            //   }
            // })
          }
          setTimeout(() => {
            this.fileLoading = false;
          }, 200);
        });
    },
    serveInfo() {
      this.$axios
        .post(
          "/p/injury/staffInfo",
          this.$qs({
            uuid: this.$route.params.uuid
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.infoData = res.data.data[0];
             this.imgData = res.data.data[0].head_img;
          }
        });
    },
    create() {
      this.tableLoading = true;
      this.$axios
        .post(
          "/p/injury/lists",
          this.$qs({
            staff_uuid: this.$route.params.uuid,
            page: this.currentPage,
            pagesize: 10,
            start_date: this.value1 ? this.value1[0] : "",
            end_date: this.value1 ? this.value1[1] : "",
            injury_position_uuid: this.partDataValue,
            treat_measure: this.input4,
            recovery_flag: this.recoveryDate === '' ? '' : Number(this.recoveryDate)
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.rows;
            this.tableTotal = res.data.data.total;
          }
          setTimeout(() => {
            this.tableLoading = false;
          }, 200);
        });
    },
    addDataLog() {
      this.dislogName = "新增伤病记录";
      this.info_uuid = "";
      this.dialogVisible = true;
      this.$nextTick(() => {
        // this.$refs['ruleForms'].resetFields();
      });

      this.$axios
        .all([
          this.$axios.post("/p/injuryset/lists", this.$qs({ type: 1 , status : 1})),
          this.$axios.post("/p/injuryset/lists", this.$qs({ type: 2 , status : 1})),
          this.$axios.post("/p/injuryset/lists", this.$qs({ type: 3 , status : 1}))
        ])
        .then(res1 => {
          this.typeData = res1[0].data.data.rows; // 损伤类型
          this.partData = res1[1].data.data.rows; // 损伤部位
          this.degreeData = res1[2].data.data.rows; // 伤病程度
        });
    },
    reBack() {
      this.$router.go(-1);
    },
    handleRemove(file) {
      let arr = "";
      this.files.forEach(item => {
        if (item.name == file.name) {
          arr = item.file_path;
        }
      });
      this.$axios
        .post(
          `/p/annex/delete`,
          this.$qs({
            file_path: arr
          })
        )
        .then(res => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: res.data.message
            });
            this.newFileList.forEach((item, index) => {
              if (item.name == file.name) {
                this.newFileList.splice(index, 1);
                this.files.splice(index, 1);
              }
            });
          } else {
            this.$message({
              type: "error",
              message: res.data.message
            });
          }
        });
    },
    remove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgDialog = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    tableSelectionChange(row) {
      this.selectChange = row;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.create();
    },
    delDataLog() {
      if (!this.selectChange.length) {
        return this.$message({
          type: "error",
          message: "请选择要删除的数据"
        });
      }
      let arr = "";
      this.selectChange.forEach((item, index) => {
        if (index == 0) {
          arr = item.uuid;
        } else {
          arr += "," + item.uuid;
        }
      });

      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios
            .post(
              "/p/injury/delete",
              this.$qs({
                uuid: arr
              })
            )
            .then(res => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: res.data.message
                });
                this.create();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    closeNew() {
      this.$refs["ruleForms"].resetFields();
      this.$set(this, "ruleForm", {});
      this.dialogVisible = false;
      this.newFileList = [];
      this.files = [];
      this.fileLoading = false;
    },
    lookClose() {
      this.lookDialogVisible = false;
      this.lookData = {};
      this.info_uuid = "";
      this.fileLoading = false;
    },
    newSave() {
      this.$refs["ruleForms"].validate(valid => {
        if (valid) {
          let data = {
            staff_uuid: this.$route.params.uuid,
            start_date: this.ruleForm.start_date,
            injury_position_uuid: this.ruleForm.injury_position_uuid,
            injury_cate_uuid: this.ruleForm.injury_cate_uuid,
            injury_degree_uuid: this.ruleForm.injury_degree_uuid,
            treat_measure: this.ruleForm.treat_measure,
            recovery_date: this.ruleForm.recovery_date,
            influence_train_days: this.ruleForm.influence_train_days,
            influence_match: this.ruleForm.influence_match,
            influence_match_name: this.ruleForm.influence_match_name,
            influence_match_days: this.ruleForm.influence_match_days,
            demo: this.ruleForm.demo,
            annex: JSON.stringify(this.files)
          };
          if (this.info_uuid != "") {
            data.uuid = this.info_uuid;
          }
          this.$axios
            .post(
              this.info_uuid ? "/p/injury/update" : "/p/injury/add",
              this.$qs(data)
            )
            .then(res => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: res.data.message
                });
                this.closeNew();
                this.create();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    beforeUpload(file) {
      console.log(file);
      const size = file.size / 1024 / 1024;
      if (size > 20) {
        this.$message({
          title: "warning",
          message: "文件大小超过20M"
        });
        return false;
      } else {
        return true;
      }
    },

    //成功的时候
    success(res, file, fileList) {
      if (res.code == 0) {
        this.files.push(file.response.data);
        // this.fileSave()
        // this.$message({
        //   type: "success",
        //   message: res.message
        // });
      } else {
        this.$message({
          type: "error",
          message: res.message
        });
        for (let i in fileList) {
          if (fileList[i].name == file.name) {
            fileList.splice(i, 1);
          }
        }
      }
      let type = this.nameMatch(file);
      if (type) {
        this.files.forEach(item => {
          console.log(item.name == file.name);
          if (item.name == file.name) {
            item.url = type;
          }
        });
      } else {
        this.files.forEach(item => {
          if (item.name == file.name) {
            item.url = "http://" + item.file_path;
          }
        });
      }
      // this.newFileList = this.files
      console.log(this.newFileList, this.files);
    },

    nameMatch(name) {
      // debugger
      console.log(name);
      let suffix = ""; // 后缀
      let result = ""; // 类型结果
      let imgUrl = "";

      var fileArr = name.name.split(".");
      suffix = fileArr[fileArr.length - 1];
      console.log(suffix);

      if (!suffix) {
        result = false;
        return result;
      }

      let typeList = [
        "png",
        "jpg",
        "jpeg",
        "bmp",
        "gif",
        "webp",
        "psd",
        "svg",
        "tiff"
      ];
      result = typeList.some(item => {
        return item == suffix;
      });
      if (result) {
        return false;
      }

      switch (suffix) {
        case "docx":
          imgUrl = this.wordUrl;
          break;
        case "rtf":
          imgUrl = this.wordUrl;
          break;
        case "xls":
          imgUrl = this.xlsxUrl;
          break;
        case "xlsx":
          imgUrl = this.xlsxUrl;
          break;
        case "ppt":
          imgUrl = this.pptsUrl;
          break;
        case "pptx":
          imgUrl = this.pptsUrl;
          break;
        case "pdf":
          imgUrl = this.pdfUrl;
          break;
        default:
          imgUrl = this.morenUrl;
          break;
      }
      return imgUrl;
    },

    delTableData(row) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$axios
            .post(
              "/p/injury/delete",
              this.$qs({
                uuid: row.uuid
              })
            )
            .then(res => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: res.data.message
                });
                this.create();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    onChangeData(data, uuid, name) {
      if (uuid == "") {
        return data;
      }
      let type = false;
      data.forEach(itm => {
        if (itm.uuid == uuid) {
          type = true;
        }
      });
      if (!type) {
        data.push({
          uuid: uuid,
          name: name
        });
      }
      return data;
    }
  }
};
</script>

<style lang="scss" scoped>
.child-top {
  .input-from {
    .searchBtn{
      margin-top: 20px;
    }
  }
  .operation-button {
    // float: left;
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
.interMant_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #fff;
  margin-bottom: 10px;
  .interTop_left {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333;
    .el-avatar {
      width: 60px;
      height: 60px;
    }
    span {
      margin-left: 5px;
      margin-right: 20px;
      font-size: 16px;
    }
    div{
      flex: 1
    }
  }
  > span {
    font-size: 16px;
    &:hover {
      cursor: pointer;
    }
  }
}
.el-select {
  width: 150px;
  margin-top: 10px;
}
.el-date-editor {
  width: 260px;
  margin-right: 20px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #ccc;
  &:focus {
    border-color: #409eff;
  }
}
.is-active {
  border-color: #409eff;
}

.content-flex-pages {
  background: #fff;
}

.form_bot {
  .interTop_left {
    overflow: hidden;
    padding: 20px;
    display: flex;
    align-items: center;
    background: #f5f5f5;
    font-size: 16px;
    margin-bottom: 20px;
    .el-avatar {
      width: 60px;
      height: 60px;
    }
    span {
      margin-right: 20px;
    }
  }
  .el-form {
    margin-bottom: 30px;
    .formItem_cen {
      display: flex;
      overflow: hidden;
      .el-form-item {
        flex: 1;
        .el-date-editor {
          width: 100%;
          border-bottom: none;
          .el-input__inner {
          }
        }
        .el-select {
          margin-top: 0;
          width: 100%;
        }
      }
    }
  }
  .inter_file {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    p {
      line-height: 16px;
      font-size: 17px;
      margin-bottom: 20px;
      span {
        display: inline-block;
        width: 5px;
        height: 16px;
        border-radius: 20px;
        background: #0055e9;
      }
    }
  }
}
.newDialog {
  /deep/ .el-dialog {
    .form_bot {
      // height: 530px;
      overflow: auto;
    }
  }
}

.append_dialog {
  /deep/ .el-dialog {
    height: 900px;
    overflow: auto;
  }
}

.tableBorder_center {
  border-left: 1px solid #ccc;
  .formItem_cen {
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    &:first-child {
      .label {
        border-top: 1px solid #ccc;
      }
      .value {
        border-top: 1px solid #ccc;
      }
    }
    .label {
      width: 15%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
    }
    .value {
      line-height: 50px;
      width: 34.4%;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      box-sizing: border-box;
    }
    .all_label {
      width: 15%;
      line-height: 50px;
      text-align: center;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
     
    }
    .all_value {
      width: 84%;
      line-height: 50px;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      box-sizing: border-box;
      
    }
    .all_value_a{
      overflow:auto
    }
  }
}

.inter_upload {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 200px;
}

.inter_opBtn {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
  padding-right: 30px;
  .upload-demo {
    margin-right: 10px;
  }
  .el-button {
    width: 110px;
    height: 40px;
    border: none;
    &:nth-child(1) {
      background: #35c8cb;
    }
    &:nth-child(2) {
      background: #6a6ef8;
    }
    &:nth-child(3) {
      background: #ed4a68;
    }
  }
}

.upload_ul {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 20px 0;
  // padding-bottom: 10px;
  li {
    width: 150px;
    text-align: center;
    margin-left: 20px;
    position: relative;
    &:last-child{
      margin-right: 20px;
    }
    .el-checkbox {
      position: absolute;
      top: 0px;
      left: 0px;
      /deep/ .el-checkbox__inner {
        width: 20px;
        height: 20px;
        &::after {
          width: 6px;
          height: 13px;
          left: 5px;
        }
      }
      /deep/ .el-checkbox__label {
        display: none;
      }
    }
    &:hover {
      cursor: pointer;
    }
    &:first-child {
      margin-left: 20px;
    }
    .img_option {
      width: 150px;
      height: 150px;
      border-radius: 10px;
      border: 1px solid #ccc;
      overflow: hidden;
      position: relative;
      // border: 2px solid #fff;
      .img_dialog {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 85, 233, 0.11);
      }
    }
    .img_active {
      border: 1px solid #0055e9;
    }
    img {
      width: 100%;
      max-height: 150px;
    }
    p {
      margin: 10px;
      font-size: 16px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
.el-pagination{
  padding-bottom: 10px;
}
.cor{
overflow: hidden;
  // text-overflow:ellipsis;
}
._omit{
  width: 900px !important;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;

}

._ret{
  margin-right: 15px;
  box-sizing: border-box;
}

._across{
  box-sizing: border-box;

    box-shadow: 0px 2px 2px 2px rgba(3, 1, 1, 0.1);
    border: 2px solid rgba(231, 228, 228, 0.1);
    border-radius:5px ;

}

._ac:hover ._across{
border: 2px solid #0055E9;

}

</style>
<style>
  .tu>img{
    width: 100%;
  }
</style>